import { resetUser } from "./userSlice";
import { resetBox } from "./boxOrderSlice";
import { resetPayment } from "./paymentSlice";
import { resetPreference } from "./preferencesSlice";
import { signOut } from "next-auth/react";

export const resetAllSlices = () => (dispatch) => {
    dispatch(resetBox());
    dispatch(resetUser());
    dispatch(resetPayment());
    dispatch(resetPreference());
    signOut({ redirect: false }).then(() => {
        window.location.href = "/login";
    });
};
