import {
    AccountSettingsIcon,
    FaqsIcon,
    LogoutIcon,
    MyBoxesIcon,
    OrderABoxIcon,
    MyRewardsIcon,
    YourPreferencesIcon,
    DashboardIcon,
} from "../../Atoms/SVGs";
import {
    DUBAI_ISO,
    USER_COUNTRY,
} from "../../../public/Constants/EnumConstants";
import { getCookie } from "cookies-next";
import {
    BigFAQs,
    BigMyBoxes,
    BigOrderABox,
    BigPreferences,
    BigRewards,
    BigSettings,
} from "../../../public/Theme";

export const SIDE_BAR_LINKS = [
    {
        icon: DashboardIcon,
        text: "Dashboard",
        link: "/dashboard",
    },
    {
        icon: OrderABoxIcon,
        text: "Order A Box",
        link: "/dashboard/orderABox",
        bigIcon: BigOrderABox,
    },
    {
        icon: MyBoxesIcon,
        text: "My Boxes",
        link: "/dashboard/myBoxes",
        bigIcon: BigMyBoxes,
    },
    {
        icon: YourPreferencesIcon,
        text: "My Style Profile",
        link: "/dashboard/myStyleProfile",
        bigIcon: BigPreferences,
    },
    {
        icon: AccountSettingsIcon,
        text: "Account Settings",
        link: "/dashboard/accountSettings",
        bigIcon: BigSettings,
    },
    {
        icon: MyRewardsIcon,
        text: "My Rewards",
        link: "/dashboard/myRewards",
        bigIcon: BigRewards,
    },
    {
        icon: FaqsIcon,
        text: "FAQs",
        link: "/dashboard/faqs",
        bigIcon: BigFAQs,
    },
    { icon: LogoutIcon, text: "Logout", link: "#" },
];

export const SIDE_BAR_LINKS_KSA = [
    {
        icon: DashboardIcon,
        text: "Dashboard",
        link: "/dashboard",
    },
    {
        icon: OrderABoxIcon,
        text: "Order A Box",
        link: "/dashboard/orderABox",
        bigIcon: BigOrderABox,
    },
    {
        icon: MyBoxesIcon,
        text: "My Style Journey",
        link: "/dashboard/myStyleJourney",
        bigIcon: BigMyBoxes,
    },
    {
        icon: YourPreferencesIcon,
        text: "My Style Profile",
        link: "/dashboard/myStyleProfile",
        bigIcon: BigPreferences,
    },
    {
        icon: AccountSettingsIcon,
        text: "Account Settings",
        link: "/dashboard/accountSettings",
        bigIcon: BigSettings,
    },

    {
        icon: FaqsIcon,
        text: "FAQs",
        link: "/dashboard/faqs",
        bigIcon: BigFAQs,
    },
    { icon: LogoutIcon, text: "Logout", link: "#" },
];

const SIDE_BAR_LINKS_UNPAID_USER = [
    { icon: LogoutIcon, text: "Logout", link: "#" },
];
export const getSidebarMenuList = (userData) => {
    if (
        userData?.payment_status === "paid" &&
        userData?.submitted_survey === true
    ) {
        if (userData?.detected_signup_country === DUBAI_ISO)
            return SIDE_BAR_LINKS;
        else return SIDE_BAR_LINKS_KSA;
    }
    return SIDE_BAR_LINKS_UNPAID_USER;
};
