import React from "react";
import Image from "next/image";
import { WearthatLoading } from "../../../public/Theme";

const WearthatLoader = ({ className }) => {
    return (
        <div
            className={
                " min-h-screen  h-screen grid justify-center items-center " +
                className
            }
        >
            <Image fill src={WearthatLoading} alt="wearthat progress loader" />
        </div>
    );
};

export default WearthatLoader;
