import React from "react";
import {
    AVATAR_CLASS_NAME,
    NORMAL_SIZE,
    SMALL_SIZE,
    MAIN_CONTAINER_CLASS,
    DASHBOARD_MENU_CONTAINER,
} from "./styles";
import { DashboardSmallMenu } from "../DashboardSidebar";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";

const UserInitialsAvatar = ({ text, topBar, breakPoint }) => {
    const style = topBar ? SMALL_SIZE : NORMAL_SIZE;
    return (
        <div className={MAIN_CONTAINER_CLASS}>
            <div className={AVATAR_CLASS_NAME + style}>
                <TypographyMontserrat
                    align={"center"}
                    fontWeight={"bold"}
                    size={topBar ? "base" : "xl"}
                    text={text}
                    color={"White"}
                />
            </div>
            {!breakPoint && (
                <div className={DASHBOARD_MENU_CONTAINER}>
                    <DashboardSmallMenu />
                </div>
            )}
        </div>
    );
};

export default UserInitialsAvatar;
